import { defineStore } from "pinia";
import {useStorage} from "@vueuse/core";

export const useMainStore = defineStore({
    id: 'main',
    state: () => ({
        loggedUserId: useStorage('abt__tok', null),
        loggedUser: useStorage('abt__usr', {}),
        pushToken: useStorage('abt__psh', ''),
        lastNotify: useStorage('abt__lNtf', null),
        hideCookieInfo: useStorage('abt__hci', false),
        publicNotifyPlateToken: useStorage('abt__pnpt', null),
        sentOrder: useStorage('abt__ord', false),
        newPushNtfId: '',
        vehiclesList: [],
        settingsTexts: {},
        settingsSecs: {},
        settingsApp: {},
        isLoading: false
    }),
    getters: {
    },
    actions: {
        startLoading() {
            this.isLoading = true;
        },
        endLoading() {
            this.isLoading = false;
        },
        logOut() {
            this.loggedUserId = null as any;
            this.loggedUser = {} as any;
            this.pushToken = '' as any;
        }
    },
});
