import {createApp} from 'vue'
import App from './App.vue'
import router from './router';

import {IonicVue} from '@ionic/vue';

/* Core CSS required for Ionic components to work properly */
import '@ionic/vue/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/vue/css/normalize.css';
import '@ionic/vue/css/structure.css';
import '@ionic/vue/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/vue/css/padding.css';
import '@ionic/vue/css/float-elements.css';
import '@ionic/vue/css/text-alignment.css';
import '@ionic/vue/css/text-transformation.css';
import '@ionic/vue/css/flex-utils.css';
import '@ionic/vue/css/display.css';

/* Theme variables */
import 'animate.css';
import './theme/variables.css';
import './assets/scss/app.scss';

import {
    faBellSlash, faUser as falUser, faMobile as falMobile, faInputText,
    faCar as falCar, faGlobe, faAt as falAt, faChartSimpleHorizontal
} from "@fortawesome/pro-light-svg-icons";
import {
    faUser as fasUser,
    faBellOn,
    faDownload,
    faArrowLeft,
    faPlus,
    faTruck,
    faArrowRight,
    faAt as fasAt,
    faBell,
    faBus,
    faMotorcycle,
    faCar as fasCar,
    faCheck,
    faChevronLeft,
    faCircleCheck,
    faCircleExclamation,
    faCircleInfo,
    faCircleQuestion,
    faRightFromBracket,
    faSliders,
    faTrashCanXmark,
    faTriangleExclamation,
    faXmark,
    faCarBurst,
    faCircleXmark,
    faSpinnerThird,
    faTrashCan, faBanParking,
    faEllipsis, faList, faEnvelope, faCheckDouble, faPaperPlaneTop, faCog, faGripDots
} from "@fortawesome/pro-solid-svg-icons";
import {faAndroid, faApple, faFacebookF, faGoogle} from "@fortawesome/free-brands-svg-icons";
import {library} from "@fortawesome/fontawesome-svg-core";
import {createPinia} from "pinia";
import moment from "moment";
import {FontAwesomeIcon} from "@fortawesome/vue-fontawesome";
import './registerServiceWorker';
import {defineCustomElements} from "@ionic/pwa-elements/loader";
import {
    faBells, faClock,
    faCommentDots,
    faLocationQuestion,
    faMessage,
    faMobile as farMobile, faSpinner,
    faUser as farUser
} from "@fortawesome/pro-regular-svg-icons";

library.add(fasCar, falCar, faAndroid, faCog, faGripDots, faSpinner, faClock, faBells, faBanParking, faLocationQuestion, faCommentDots, faCheckDouble, faPaperPlaneTop, faMessage, faApple, faEnvelope, faInputText, faSliders, faCarBurst, faList, farMobile, falMobile, faEllipsis, faTrashCan, faSpinnerThird, faCircleXmark, fasUser, falUser, farUser, faRightFromBracket, faChartSimpleHorizontal, faTrashCanXmark, faChevronLeft, faPlus, faBell, faBellOn, faArrowRight, faArrowLeft, faCircleQuestion, faMotorcycle, faBus, faTruck, faCheck, faXmark, faTriangleExclamation, faCircleInfo, faCircleCheck, fasAt, falAt, faApple, faGoogle, faFacebookF, faDownload, faBellSlash, faCircleExclamation, faGlobe);

const app = createApp(App);

app.config.globalProperties.$filters = {
    formatDateToUnix(value: any = new Date()) {
        return moment(value).unix();
    },
    formatDateFull(value: any, fromUnix = true) {
        if (fromUnix) {
            return moment.unix(value).format('DD MMM yyyy');
        } else {
            return moment(value).format('DD MMM yyyy');
        }
    },
    formatDateShort(value: any, fromUnix = true) {
        if (fromUnix) {
            return moment.unix(value).format('DD MMM');
        } else {
            return moment(value).format('DD MMM');
        }
    },
    formatDateTime(value: any, fromUnix = true) {
        if (fromUnix) {
            return moment.unix(value).format('DD MMM yyyy HH:mm:ss');
        } else {
            return moment(value).format('DD MMM yyyy HH:mm:ss');
        }
    },
    formatDateTimeISO(value: any, fromUnix = false) {
        if (fromUnix) {
            return moment.unix(value).format('YYYY-MM-DDTHH:mmZ');
        } else {
            return moment(value).format('YYYY-MM-DDTHH:mmZ');
        }
    },
    formatTime(value: any, fromUnix = true, addHours = 0) {
        if (fromUnix) {
            return moment.unix(value).add(addHours, 'hours').format('HH:mm');
        } else {
            return moment(value).add(addHours, 'hours').format('HH:mm');
        }
    },
    isBase64(str: any) {
        if (str === '' || str.trim() === ''){ return false; }
        try {
            return btoa(atob(str)) == str;
        } catch (err) {
            return false;
        }
    }
}

app.use(IonicVue);
app.use(createPinia());
app.use(router);
app.component('fa-icon', FontAwesomeIcon);

router.isReady().then(() => {
    app.mount('#app');
});

defineCustomElements(window);
