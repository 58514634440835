import {initializeApp} from "firebase/app";
import {getFunctions} from "firebase/functions";
import {initializeAuth, indexedDBLocalPersistence, getAuth} from "firebase/auth";
import { initializeAppCheck, ReCaptchaV3Provider } from "firebase/app-check";
import {Capacitor} from "@capacitor/core";
// eslint-disable-next-line
import { getFirestore, connectFirestoreEmulator } from "firebase/firestore";

const firebaseConfig = {
    apiKey: process.env.VUE_APP_FIRE_API_KEY,
    authDomain: process.env.VUE_APP_FIRE_AUTH_DOMAIN,
    projectId: process.env.VUE_APP_FIRE_PROJECT_ID,
    storageBucket: process.env.VUE_APP_FIRE_STORAGE_BUCKET,
    messagingSenderId: process.env.VUE_APP_FIRE_MESSAGING_SENDER_ID,
    appId: process.env.VUE_APP_FIRE_APP_ID
};
const app = initializeApp(firebaseConfig);
const db = getFirestore(app);
const functions = getFunctions(app);
let auth;

if (Capacitor.isNativePlatform()) {
    auth = initializeAuth(app, {
        persistence: indexedDBLocalPersistence
    });
} else {
    auth = getAuth(app);
    initializeAppCheck(app, {
        provider: new ReCaptchaV3Provider(process.env.VUE_APP_RECAPTCHA_SITE_KEY),
        isTokenAutoRefreshEnabled: true
    });
}

// connectFirestoreEmulator(db, 'localhost', 9090);
export { app, auth, db, functions };
