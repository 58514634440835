<template>
	<ion-page>
		<ion-tabs>
			<ion-router-outlet></ion-router-outlet>
			<div class="tab-bar-container">
				<ion-tab-bar slot="bottom" class="user-tab-bar">
					<ion-tab-button tab="home" href="/user/home">
						<i>
							<fa-icon :icon="['fas', 'car']" />
						</i>
					</ion-tab-button>
					<ion-tab-button tab="notifications" class="tab-notifications" href="/user/notifications">
						<i>
							<b v-if="ntfsCount">{{ ntfsCount }}</b>
							<fa-icon :icon="['fas', (ntfsCount ? 'bell-on' : 'bell')]" />
						</i>
					</ion-tab-button>
					<ion-tab-button tab="settings" href="/user/settings">
						<i>
							<fa-icon :icon="['fas', 'cog']" />
						</i>
					</ion-tab-button>
				</ion-tab-bar>
			</div>
		</ion-tabs>
	</ion-page>
</template>

<script>
import {
	IonTabBar,
	IonTabButton,
	IonTabs,
	IonPage,
	IonRouterOutlet, toastController
} from '@ionic/vue';
import {defineComponent} from "vue";
import {collection, query, where, onSnapshot} from "firebase/firestore";
import { db } from "@/firebase";
import {useMainStore} from "@/stores/main";

export default defineComponent({
	name: 'UserLayout',
	components: {IonTabs, IonTabBar, IonTabButton, IonPage, IonRouterOutlet},
	setup: () => ({store: useMainStore()}),
	data() {
		return {
			ntfsCount: 0,
			unsubscribeNtfs: null
		}
	},
	created() {
		this._updateNotificationsCount();
	},
	beforeUnmount() {
		this.unsubscribeNtfs();
	},
	methods: {
		async _updateNotificationsCount() {
			const toast = await toastController
				.create({
					duration: 1000,
					cssClass: 'info',
					keyboardClose: true,
					message: 'Ai o notificare necitită!',
				});

			this.unsubscribeNtfs = onSnapshot(query(collection(db, 'notifications', this.store.loggedUserId, 'notificationsList'), where('read', '==', false)), async (snap) => {
				if (snap.size !== this.ntfsCount) {
					await toast.present();
					this.ntfsCount = snap.size;
				}
			});
		}
	}
});
</script>

<style scoped lang="scss">
@import './src/assets/scss/vars';

.user-tab-bar {
	background: $primary;
	height: 50px;
	margin: -15px 18px 15px;
	border-radius: 20px;
	border: 2px solid white;
	padding-bottom: 0!important;

	ion-tab-button {
		background: none;
		color: white;

		i {
			border-radius: 100%;
			width: 50px;
			height: 50px;
			display: inline-flex;
			align-items: center;
			justify-content: center;

			svg {
				font-size: 24px;
				opacity: 0.4;
				color: white;
			}
		}

		&.tab-selected {

			i {

				svg {
					opacity: 1;
				}
			}
		}

		&.tab-notifications {

			i {
				position: relative;

				b {
					white-space: nowrap;
					position: absolute;
					background: $red;
					color: white;
					width: 24px;
					line-height: 20px;
					border-radius: 100%;
					top: 0;
					right: 0;
					font-style: normal;
					border: 2px solid $primary;
					z-index: 9;
				}
			}
		}
	}
}
</style>
