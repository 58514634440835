import {createRouter, createWebHistory} from '@ionic/vue-router';
import {RouteRecordRaw} from 'vue-router';
import UserLayout from "@/views/layouts/UserLayout.vue";
import AdminLayout from "@/views/layouts/AdminLayout.vue";
import {Capacitor} from "@capacitor/core";

const routes: Array<RouteRecordRaw> = [
    {
        path: '/',
        redirect: '/login'
    },
    {
        path: '/login',
        name: 'Login',
        component: () => import('@/views/public/LoginPage.vue'),
        beforeEnter: (to, from, next) => {
            if (localStorage.getItem('abt__tok')) {
                next({name: 'UserHome'});
            } else if (Capacitor.getPlatform() === 'web' && location.hostname !== 'localhost') {
                next({name: 'PublicNotify'});
            } else {
                next();
            }
        }
    },
    {
        path: '/get-app/:id?',
        name: 'PublicNotify',
        component: () => import('@/views/public/PublicNotifyPage.vue'),
        beforeEnter: (to, from, next) => {
            if (localStorage.getItem('abt__tok')) {
                next({name: 'UserHome'});
            } else {
                next();
            }
        }
    },
    {
        path: '/user/',
        component: UserLayout,
        children: [
            {
                path: '',
                redirect: 'home'
            },
            {
                path: 'home',
                name: 'UserHome',
                component: () => import('@/views/user/HomePage.vue')
            },
            {
                path: 'notifications',
                name: 'UserNotifications',
                component: () => import('@/views/user/NotificationsPage.vue')
            },
            {
                path: 'settings',
                name: 'UserSettings',
                component: () => import('@/views/user/SettingsPage.vue')
            },
            {
                path: 'notify',
                name: 'UserNotify',
                component: () => import('@/views/user/NotifyPage.vue')
            },
            {
                path: 'downloads/:type?',
                name: 'UserDownloads',
                component: () => import('@/views/user/DownloadsPage.vue')
            },
            {
                path: 'vehicle/:id',
                name: 'UserVehicle',
                component: () => import('@/views/user/VehiclePage.vue')
            },
        ],
        beforeEnter: (to, from, next) => {
            if (!localStorage.getItem('abt__tok')) {
                next({name: 'Login'});
            } else {
                next();
            }
        }
    },
    {
        path: '/zeu',
        name: 'AdminLogin',
        component: () => import('@/views/admin/AdminLoginPage.vue'),
        beforeEnter: (to, from, next) => {
            if (Capacitor.getPlatform() === 'web' && localStorage.getItem('zeu__tok') && localStorage.getItem('zeu__tok') === process.env.VUE_APP_ZEU_TOKEN) {
                next({name: 'AdminDashboard'});
            } else {
                next();
            }
        }
    },
    {
        path: '/adm/',
        component: AdminLayout,
        children: [
            {
                path: '',
                redirect: 'dashboard'
            },
            {
                path: 'dashboard',
                name: 'AdminDashboard',
                component: () => import('@/views/admin/AdminDashboardPage.vue')
            },
            {
                path: 'users',
                name: 'AdminUsers',
                component: () => import('@/views/admin/AdminUsersPage.vue')
            },
            {
                path: 'vehicles',
                name: 'AdminVehicles',
                component: () => import('@/views/admin/AdminVehiclesPage.vue')
            },
            {
                path: 'notifications',
                name: 'AdminNotifications',
                component: () => import('@/views/admin/AdminNotificationsPage.vue')
            },
            {
                path: 'forms',
                name: 'AdminForms',
                component: () => import('@/views/admin/AdminFormsPage.vue')
            },
        ],
        beforeEnter: (to, from, next) => {
            if (!localStorage.getItem('zeu__tok') || localStorage.getItem('zeu__tok') !== process.env.VUE_APP_ZEU_TOKEN) {
                next({name: 'PublicNotify'});
            } else {
                next();
            }
        }
    }
]

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
})

export default router
