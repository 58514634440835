<template>
	<ion-app>
		<div class="abt__devi" v-if="isWeb">
			<div class="logo ion-text-center">
				<a href="https://amblocat.eu">
					<ion-img :src="require('./assets/images/logo-color-black.svg')"></ion-img>
				</a>
			</div>
			<div class="get-app">
				<div class="page-title m10-b">
					<h1>Descarcă aplicația</h1>
				</div>
				<div class="app-links">
					<a href="https://apple.co/3HCHt9v" target="_blank">
						<ion-img :src="require('./assets/images/appstore.png')"></ion-img>
					</a>
					<a href="http://bit.ly/3FYzEcY" target="_blank">
						<ion-img :src="require('./assets/images/playstore.png')"></ion-img>
					</a>
					<a href="http://bit.ly/3Yr5QwJ" target="_blank">
						<ion-img :src="require('./assets/images/appgallery.png')"></ion-img>
					</a>
				</div>
			</div>
			<p class="copy">&copy; 2023 AmBlocat &bull; Toate drepturile rezervate.</p>
		</div>
		<div :class="isWeb && 'abt__movi'">
			<ion-router-outlet/>
			<ion-loading :is-open="isLoading" message="Loading..." :translucent="true" spinner="crescent"></ion-loading>
			<ion-modal ref="modalVersion" :is-open="checkVersionOpen" :can-dismiss="!mustUpdateVersion">
				<ion-header>
					<ion-toolbar>
						<ion-buttons slot="end" v-if="!mustUpdateVersion">
							<ion-button @click="dismiss()">Închide</ion-button>
						</ion-buttons>
					</ion-toolbar>
				</ion-header>
				<ion-content class="ion-padding">
					<p>Există o versiune mai nouă a acestei aplicații.</p>
					<p v-if="!mustUpdateVersion">Te rugăm să actualizezi.</p>
					<p v-if="mustUpdateVersion">Pentru a mai putea utiliza, este necesar să actualizezi aplicația.</p>
					<a :href="updateLink" v-if="updateLink" class="btn btn-primary m15-t">Update</a>
				</ion-content>
			</ion-modal>
			<div class="cookie-notice" v-if="isWeb && !store.hideCookieInfo">
				<p>Acest site folosește cookie-uri. Navigând în continuare, îți exprimi acordul asupra folosirii acestora. <a target="_blank" href="https://amblocat.eu/termeni-si-conditii#cookies">Mai multe detalii</a></p>
				<a @click="store.hideCookieInfo = true">Accept</a>
				<a rel="nofollow" href="https://google.ro">Renunț</a>
			</div>
		</div>
	</ion-app>
</template>

<script>
import {
	IonApp, IonLoading, IonRouterOutlet, IonContent, IonModal, IonHeader,
	IonToolbar,
	IonButton, IonImg,
	IonButtons, useBackButton, useIonRouter, toastController
} from '@ionic/vue';
import {defineComponent} from 'vue';
import {Capacitor} from "@capacitor/core";
import {StatusBar, Style} from "@capacitor/status-bar";
import {mapState} from "pinia";
import {useMainStore} from "@/stores/main";
import moment from "moment";
import {App} from "@capacitor/app";
import {addDoc, collection, doc, getDoc, getDocs, query, updateDoc} from "firebase/firestore";
import {auth, db, functions} from "@/firebase";
import {signOut} from "firebase/auth";
import {httpsCallable} from "firebase/functions";
import {PushNotifications} from "@capacitor/push-notifications";

export default defineComponent({
	name: 'App',
	components: {
		IonApp,
		IonRouterOutlet,
		IonLoading,
		IonContent,
		IonModal,
		IonHeader,
		IonToolbar,
		IonButton,
		IonButtons,
		IonImg
	},
	computed: {
		...mapState(useMainStore, ["isLoading"]),
		isIos() {
			return Capacitor.getPlatform() === 'ios';
		},
		isWeb() {
			return Capacitor.getPlatform() === 'web';
		}
	},
	setup() {
		const ionRouter = useIonRouter();
		useBackButton(-1, () => {
			if (!ionRouter.canGoBack()) {
				App.exitApp();
			}
		});

		return {
			ionRouter: ionRouter,
			store: useMainStore()
		}
	},
	data() {
		return {
			env: process.env,
			checkVersionOpen: false,
			mustUpdateVersion: false,
			updateLink: ''
		}
	},
	created() {
		moment.locale('ro');
		this._initMobileFunctions();
		this._fetchAppSettings();
		this._checkHasInvites();
	},
	methods: {
		dismiss() {
			this.$refs.modalVersion.$el.dismiss();
		},
		_initMobileFunctions() {
			if (Capacitor.getPlatform() !== 'web') {
				this._setStatusBarStyleLight();
				this._checkIfStillLogged();
				this._clearIconBadge();
				this._pushNtfListener();
			}
		},
		async _pushNtfListener() {
			// PushNotifications.addListener('pushNotificationReceived',
			// 	async (notification) => {
			// 		const alert = await alertController
			// 			.create({
			// 				header: notification.title,
			// 				message: notification.body,
			// 				buttons: ['OK'],
			// 			});
			// 		await alert.present();
			// 	},
			// );
			PushNotifications.addListener('pushNotificationActionPerformed',
				async (data) => {
					this.store.newPushNtfId = data.notification.data.notificationId;
					this.ionRouter.replace({name: 'UserNotifications'});
				});
		},
		async _fetchAppSettings() {
			await getDocs(query(collection(db, 'settings')))
				.then(async querySnap => {
					querySnap.forEach((doc) => {
						switch (doc.id) {
							case 'app':
								this.store.settingsApp = doc.data();
								break;
							case 'texts':
								this.store.settingsTexts = doc.data();
								break;
							case 'secs':
								this.store.settingsSecs = doc.data();
								break;
						}
					});
				});
			if (Capacitor.getPlatform() !== 'web') {
				this._checkAppVersion();
			}
		},
		async _checkIfStillLogged() {
			if (this.store.loggedUserId) {
				const docSnap = await getDoc(doc(db, 'pushTokens', this.store.loggedUserId));

				if (docSnap.exists()) {
					const tokensData = docSnap.data(),
						tokensList = tokensData.tokensList;
					const toast = await toastController
						.create({
							duration: 4000,
							cssClass: 'error',
							keyboardClose: true,
							message: 'Acest cont este logat pe alt device.',
						});

					if (tokensList &&
						tokensList.length &&
						tokensList[0] !== this.store.pushToken) {
						await signOut(auth);
						this.store.loggedUser = {};
						this.store.loggedUserId = null;
						this.store.pushToken = '';
						await toast.present();
						return this.ionRouter.replace({name: 'Login'});
					}
				}
			}
		},
		async _clearIconBadge() {
			if (this.store.pushToken) {
				const clearIconBadge = httpsCallable(functions, 'clearIconBadge');

				await clearIconBadge({token: this.store.pushToken});
			}
		},
		async _updateLoggedUser() {
			const docSnap = await getDoc(doc(db, 'users', this.store.loggedUserId));

			if (docSnap.exists()) {
				this.store.loggedUser = docSnap.data();
				if (!this.store.loggedUser.settings) {
					this.store.loggedUser.settings = {};
				}

				await updateDoc(doc(db, 'users', this.store.loggedUserId), {
					lastActive: new Date(),
					currentAppVersion: this.env.VUE_APP_VERSION
				});
			}
		},
		async _checkHasInvites() {
			if (this.store.loggedUserId) {
				await this._updateLoggedUser();
				if (!this.store.loggedUser.hasInvites && (this.store.loggedUser.invites && this.store.loggedUser.invites.length)) {
					const checkInvitedEmails = httpsCallable(functions, 'checkInvitedEmails');

					checkInvitedEmails()
						.then(async (resp) => {
							const invites = resp.data.invites;

							this.store.loggedUser.invites = invites;
							if (invites.every(item => item.valid)) {
								const ntfObj = {
									from: 'admin',
									type: 'success',
									text: 'Felicitări! Toți prietenii invitați au fost validați. Acum poți folosi noile funcționalități.',
									plate: null,
									createTime: new Date(),
									canRespond: false,
									read: false
								};

								this.store.loggedUser.hasInvites = invites.length;
								await updateDoc(doc(db, 'users', this.store.loggedUserId), {
									hasInvites: invites.length
								});
								await addDoc(collection(db, 'notifications', this.store.loggedUserId, 'notificationsList'), ntfObj);
							}
						});
				}
			}
		},
		_checkAppVersion() {
			if (this.store.settingsApp && this.store.settingsApp.lastVersionCode) {
				const currentVersionCode = this.env.VUE_APP_VERSION_CODE;

				if (currentVersionCode && this.store.settingsApp.lastVersionCode > currentVersionCode) {
					this.checkVersionOpen = true;
					this.mustUpdateVersion = this.store.settingsApp.mustUpdate;
					this.updateLink = Capacitor.getPlatform() === 'ios' ? 'https://apple.co/3HCHt9v' : 'https://bit.ly/3FYzEcY';
				}
			}
		},
		async _setStatusBarStyleLight() {
			await StatusBar.setStyle({style: Style.Light});
			await StatusBar.setOverlaysWebView({overlay: true});
		},
	}
});
</script>

<style scoped lang="scss">
@import './src/assets/scss/vars';

.abt__devi {
	padding: 50px;

	.logo {
		margin-bottom: 50px;

		ion-img {
			width: 300px;
			margin: 5px auto 15px;
		}
	}

	.page-title {
		justify-content: center;

		h1 {
			margin-bottom: 30px;
		}
	}

	.get-app {
		margin: 30px 0;

		.app-links {
			display: flex;
			flex-wrap: wrap;
			justify-content: space-around;

			a {
				width: 160px;
				margin-bottom: 15px;
			}
		}
	}

	.copy {
		font-size: 12px;
		text-align: center;
		color: black;
	}
}

.cookie-notice {
	box-shadow: 0 4px 25px rgba(0, 0, 0, 0.35);
	position: fixed;
	bottom: 0;
	width: 100%;
	z-index: 999;
	padding: 10px;
	background: white;
	text-align: center;

	p {
		color: black;
		margin-top: 0;
		font-size: 12px;
		margin-bottom: 5px;

		a {
			color: $primary;
			text-decoration: none;
		}
	}

	>a {
		color: black;
		font-weight: 700;
		text-decoration: none;
		font-size: 14px;
		margin: 0 10px;

		&:last-child {
			color: $grey;
		}
	}
}

@media only screen and (min-width: 575px) {
	.abt__devi {
		display: block!important;
	}

	.abt__movi {
		display: none;
	}
}
</style>
