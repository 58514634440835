<template>
	<ion-page>
		<ion-router-outlet></ion-router-outlet>
	</ion-page>
</template>

<script>
import {
	IonPage,
	IonRouterOutlet
} from '@ionic/vue';
import {defineComponent} from "vue";

export default defineComponent({
	name: 'AdminLayout',
	components: {IonPage, IonRouterOutlet}
});
</script>

<style scoped lang="scss">
@import './src/assets/scss/vars';

</style>
